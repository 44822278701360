.featured-min {
  @include flex;
  @include flex-align-stretch;
  @include flex-justify-start;
  gap: var(--indent-xs);
  padding: var(--indent-l);
  border-radius: var(--indent-xs);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.18);

  &__img {
    width: 176px;
    height: 150px;
    overflow: hidden;
    border-radius: var(--indent-s);
    flex-shrink: 0;

    @include mobile {
      width: 120px;
      height: auto;
    }

    img {
      @extend %img-cover;
    }
  }

  &__content {
    @include flex-column;
    @include flex-align-stretch;
    gap: var(--indent-m);
  }

  &__date {
    margin-top: auto;
  }
}
