.list {
  @include flex-column;
  gap: var(--indent-m);

  li {
    position: relative;
    padding-left: 28px;

    @include before {
      width: 16px;
      height: 16px;
      background-color: var(--color-primary);
      border-radius: 2px;
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}
