@import "../../node_modules/swiper/swiper-bundle";

.dashboard-hero {
  @include flex;
  width: 100%;
  height: auto;
  gap: var(--indent-m);
  padding: var(--indent-l);
  border-radius: var(--indent-m);
  border: 3px solid var(--color-light-green);

  @include small-tablet {
    flex-direction: column;
    padding: 0;
    border: initial;
    margin-bottom: 20px;
  }

  &__info {
    @include flex;
    @include flex-column;
    width: 100%;
    gap: 10px;

    &._w400 {
      max-width: 300px;
    }

    &._border-grey {
      border-color: var(--color-grey);
      justify-content: flex-start;
      gap: 0;
    }
  }

  &__title {
    @extend .second-title;

    @include for-desktop {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      &._name {
        -webkit-line-clamp: 2;
      }
    }
  }

  &__info-footer {
    @include flex-center-between;
    @include flex-wrap;
    gap: var(--indent-m);
    margin-top: auto;
  }

  &__slider {
    @include flex;
    width: 506px;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;

    @include tablet {
      width: 100%;
    }
  }

  &__img {
    width: 300px;
    height: 100%;
    flex-shrink: 0;
    border-radius: var(--indent-s);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
      border-radius: var(--indent-s);
    }

    @include tablet {
      height: auto;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__img-btn {
    @extend .add-photo-min;
    position: absolute;
    bottom: var(--indent-l);
    right: var(--indent-l);
    z-index: 2;
  }

  &__info-btns {
    @include flex-center-end;
    gap: var(--indent-s);
  }

  &__info-btn {
    @extend .add-photo-min;
  }

  &__photo {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    position: relative;
    background-color: var(--color-grey);
    border-radius: var(--indent-s);
    overflow: hidden;

    @include small-tablet {
      width: 300px;
      height: 300px;
    }

    @include mobile {
      width: 100%;
      height: auto;
    }

    .dashboard-hero__img-btn {
      right: 10px;
      bottom: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: var(--indent-s);
      overflow: hidden;
    }
  }

  &__photos {
    width: 100%;

    .swiper-slide {
      max-width: 204px;
      width: 100%;
      flex-shrink: 0;
      border-radius: var(--indent-s);
      background-color: var(--color-grey);
      overflow: hidden;
      cursor: pointer;
      position: relative;

      .delete-min {
        position: absolute;
        z-index: 2;
        bottom: 10px;
        right: 10px;
        opacity: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;

        transition: transform 0.25s ease;
      }

      @include hover {
        .delete-min {
          opacity: 1;
        }

        img {
          transform: scale(1.05);
        }
      }
    }
  }
}
