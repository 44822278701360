.menu-link {
  @include flex-center-start;
  @extend .big-24;
  @include text-decoration-none;
  gap: var(--indent-xs);
  color: var(--color-black);
  transition: color 0.25s ease;
  padding: var(--indent-s) 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    transform: translateY(-2px);
    path {
      transition: stroke 0.25s ease;
      stroke: currentColor;
    }
  }

  @include tablet {
    padding: 0;
  }

  &--sub {
    display: flex;
    align-items: center;
    gap: 4px;

    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      transition: transform 0.25s ease;
      background-image: url("@img/icons/select-arrow.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @include tablet {
        display: none;
      }
    }
  }

  &:hover {
    color: var(--color-primary);
  }
}
