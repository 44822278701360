.suggestions {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  padding: var(--indent-s) 0;
  border-radius: var(--indent-s);
  background-color: var(--color-white);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
  transition: opacity 0.3s ease-out;

  &__item {
    @extend .text;
    padding: var(--indent-s) var(--indent-l);
    transition: background-color 0.3s ease;
    cursor: pointer;

    span {
      font-size: var(--indent-s);
      color: var(--color-grey-dark);
    }

    @include hover {
      background-color: var(--color-grey);
    }
  }

  &._show {
    opacity: 1;
    pointer-events: auto;
  }
}
