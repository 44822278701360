.grid3 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: var(--indent-l);

  @include mobile {
    grid-template-columns: 1fr;
  }
}
