.grid3-min {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--indent-l);

  @include tablet {
    grid-template-columns: 1fr;
  }
}
