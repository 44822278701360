.presentation-slider {
  position: relative;
  border-radius: var(--indent-s);
  overflow: hidden;

  .swiper-slide {
    height: auto;
    position: relative;
    z-index: 1;

    .video {
      position: relative;
      z-index: 1;
    }
    > *,
    img {
      @extend %img-cover;
    }
  }

  &__btn {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: var(--indent-s);

    &._prev {
      left: var(--indent-s);
    }

    &._next {
      right: var(--indent-s);
    }

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
