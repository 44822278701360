.latest-news {
  @include flex-column;
  gap: var(--indent-m);

  &__img {
    width: 100%;
    height: 244px;
    border-radius: var(--indent-s);
    overflow: hidden;

    img {
      @extend %img-cover;
    }
  }
}
