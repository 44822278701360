.notification {
  position: relative;

  @include before {
    width: 100%;
    height: calc(100% + 10px);
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
  }

  &__container {
    position: absolute;
    z-index: 3;
    right: 0;
    top: calc(100% + 10px);
    width: 400px;

    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;
  }

  @include for-desktop {
    &:hover {
      .notification__container {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__header {
    @include flex-center-between;
    gap: var(--indent-s);
    padding: var(--indent-l) var(--indent-m);
  }

  &__title {
    @extend .text;
    color: #1b1b1b;
  }

  &__mark {
    @include btn-reset;
    @include text-decoration-none;
    @extend .text;
    color: var(--color-primary-dark);
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__content {
    @include flex-column;
    gap: 2px;
    max-height: 290px;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: var(--color-grey);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 12px;
    }
  }

  &__item {
    width: 100%;
  }

  &__all {
    @include btn-reset;
    @include text-decoration-none;
    @extend .text;
    @include flex-all-center;
    width: 100%;
    text-align: center;
    padding: var(--indent-s);
    transition: color 0.25s ease;

    &:hover {
      color: var(--color-primary);
    }
  }

  &__btn {
    position: relative;
    z-index: 2;
    @include tablet {
      justify-content: flex-start;
    }
  }
}
