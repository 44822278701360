.form-center {
  @include flex;
  @include flex-column;
  @include flex-align-center;
  gap: var(--indent-l);
  width: 100%;
  max-width: 940px;

  .input {
    width: 100%;
  }
}
