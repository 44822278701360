.latest-new {
  @include flex-column;
  @include flex-align-stretch;
  overflow: hidden;
  border-radius: var(--indent-s);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
  background-color: #fff;

  &._green {
    background-color: var(--color-light-green);
  }

  &__img {
    width: 100%;
    height: 244px;

    &._h370 {
      height: 370px;
    }

    @include tablet {
      height: auto;
    }

    img {
      @extend %img-cover;
    }
  }

  &__content {
    @include flex-column;
    @include flex-align-stretch;
    height: 100%;
    padding: var(--indent-l);
    gap: var(--indent-l);

    > *:not(.btn) {
      color: #1b1b1b;
    }
  }

  &__link {
    margin-top: auto;
  }
}
