.state-map {
  background-color: var(--color-light-green);
  //overflow: auto;

  &__container {
    //min-width: 1000px;

    svg {
      width: 100%;
      height: auto;

      text {
        font-family: var(--font-family);
      }

      path {
        transition: fill 0.25s ease;

        &:not(.no-hover) {
          &:hover {
            fill: var(--color-primary-hover);
          }
        }
      }

      text {
        pointer-events: none;
      }
    }
  }
}
