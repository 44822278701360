.popular-home {
  height: 100%;

  &__container {
    @include text-decoration-none;
    @include flex-stretch-between;
    @include flex-column;
    height: 100%;
    position: relative;
    outline: none;
    @include hover {
      .popular-home__img img {
        transform: scale(1.1);
      }

      .popular-home__header {
        border-color: var(--color-primary);
      }
    }
  }

  &__header {
    @include flex-center-between;
    gap: var(--indent-s);
    height: 100%;
    padding: 36px var(--indent-l);
    border-radius: var(--indent-s) var(--indent-s) 0 0;
    border-top: 3px solid var(--color-light-green);
    border-left: 3px solid var(--color-light-green);
    border-right: 3px solid var(--color-light-green);
    transition: border 0.25s linear;
    > .star {
      flex-shrink: 0;
    }

    > * {
      color: var(--color-black);
    }
  }

  &__img {
    width: 100%;
    height: 360px;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 1;
    border-radius: 0 0 var(--indent-s) var(--indent-s);
    img {
      transition: transform 0.25s linear;
      @extend %img-cover;
    }
  }

  &__description {
    @include text-decoration-none;
    @include flex-all-center;
    background-color: #fff;
    border-radius: var(--indent-s);
    color: #1b1b1b;
    text-align: center;
    padding: var(--indent-s);
    width: calc(100% - 40px);
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 20px;
  }
}
