.reg-account-info {
  @include flex-column;
  @extend .container;
  gap: var(--indent-2xl);
  padding-top: var(--indent-l);
  padding-bottom: var(--indent-2xl);

  &__container {
    @include flex-start-between;
    gap: var(--indent-l);

    @include small-tablet {
      flex-direction: column;
    }
  }

  &__photo {
    flex-shrink: 0;
    position: relative;
    border-radius: var(--indent-s);
    overflow: hidden;
    max-width: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
      border-radius: var(--indent-s);
    }
  }

  &__img-btn {
    @extend .dashboard-hero__img-btn;
  }

  &__content {
    @include flex-column;
    width: 100%;
    gap: var(--indent-2xl);
  }

  &__item {
    @include flex-start-between;
    gap: var(--indent-l);

    @include mobile {
      flex-direction: column;
    }
  }

  &__btn {
    flex-shrink: 0;
    padding: var(--indent-s) var(--indent-2xl);

    @include mobile {
      width: 100%;
    }
  }

  &__names {
    @include flex-column;
    gap: var(--indent-l);
    width: calc(100% - 300px);

    @include tablet {
      width: 100%;
    }
  }

  &__name {
    @include flex-column;
    gap: var(--indent-s);
  }
}
