.popular-project {
  @include text-decoration-none;
  @include flex-center-between;
  gap: var(--indent-l);
  padding: var(--indent-l);
  border-radius: var(--indent-s);
  background-color: #fff;

  &__container {
    @include text-decoration-none;
    @include flex-center-between;
    gap: var(--indent-l);
  }

  &__logo {
    @include flex-all-center;
    flex-shrink: 0;
    width: 82px;
    height: 82px;
    border-radius: var(--indent-s);
    border: 3px solid var(--color-light-green);
    background: var(--White, #fff);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    @include flex-column;
    width: 100%;
    gap: var(--indent-s);

    > * {
      color: #1b1b1b;
    }
  }

  &__title {
    @extend .text;
    font-weight: 700;
  }

  &__text {
    @extend .text;
  }
}
