.task-progress {
  &__text {
    color: var(--color-grey-dark);
  }

  &__line {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: var(--color-grey);
    position: relative;
    overflow: hidden;
  }

  &__line-progress {
    display: block;
    width: 14%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.25s ease;
    background-color: var(--color-primary);
  }
}
