.grid5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--indent-l);

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include small-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    grid-template-columns: 1fr;
  }
}
