.show-more {
  @include text-decoration-none;
  @include btn-reset;
  @include flex-center-start;
  @include after {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: currentColor;
    mask-image: url("@img/icons/arrow-forward-double.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;

    @include small-tablet {
      width: 18px;
      height: 18px;
    }
  }
}
