.order-for-pro {
  @include flex-column;
  gap: var(--indent-l);
  width: 100%;
  border-radius: var(--indent-m);
  padding: var(--indent-l);
  background-color: var(--color-pink);

  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    @include flex;
    gap: var(--indent-m);
    > * {
      color: #1b1b1b;
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__btns {
    @include flex;
    @include flex-justify-end;
    width: 100%;
    gap: var(--indent-m);
  }
}
