%header-btn-ico {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: currentColor;
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;

  transform: translateY(-2px);
}

.header-btn {
  @include btn-reset;
  @include flex-all-center;
  @extend .text-bold;
  line-height: 1;
  gap: var(--indent-xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s ease;

  svg {
    transform: translateY(-2px);

    path {
      transition: stroke 0.25s ease;
      stroke: currentColor;
    }
  }


  &._explore {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/menu/megaphone.svg");
    }
  }

  &._claim {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/menu/check-circle-2.svg");
    }

  }

  &._account {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/user-circle.svg");
    }
  }

  &._profile {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/mdi_user.svg");
    }
  }

  &._setting {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/settings.svg");
    }
  }

  &._logout {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/forward.svg");
    }
  }

  &._business {
    @include before {
      @extend %header-btn-ico;
      mask-image: url("@img/icons/menu/rocket.svg");
    }
  }

  &._active {
    color: var(--color-primary);

    @include hover {
      color: var(--color-primary-dark);
    }

    @include mobile {
      color: currentColor;
    }
  }

  @include hover {
    color: var(--color-primary);
  }
}
