.order-address {
  @include flex-column;
  gap: var(--indent-l);
  padding: var(--indent-l);
  border-radius: var(--indent-m);
  border: 2px solid var(--color-grey);
  background-color: #ffffff;

  > * {
    color: #1b1b1b;
  }

  @include small-tablet {
    padding: var(--indent-s);
    gap: var(--indent-s);
  }

  &__header {
    @include flex-center-start;
    gap: var(--indent-m);

    @include before {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      background-image: url("@img/icons/location.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @include small-tablet {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__text {
    @include flex-column;
    gap: var(--indent-xs);
  }

  &__map {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;

    > * {
      @extend %img-cover;
    }
  }
}
