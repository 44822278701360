.scroll-block {
  max-width: calc(var(--container-width) - 30px);
  padding: 5px;
  //width: calc(100vw - ((100vw - var(--content-width)) / 2) - 60px);
  overflow: auto;
  padding-bottom: var(--indent-xl);
  cursor: all-scroll;
  transform: translate(-5px, -5px);

  @include tablet {
    width: calc(100vw - (var(--container-offset) * 2));
  }

  &::-webkit-scrollbar {
    width: var(--indent-xl);
    height: var(--indent-xl);
    border-radius: var(--indent-l);
    background: #ffffff;
    box-shadow: 0px 6px 8px 5px rgba(0, 0, 0, 0.12) inset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: var(--indent-xl);
    transition: background-color 0.25s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary-hover);
  }
}

.w366 {
  @include flex;
  @include flex-align-stretch;
  @include flex-justify-start;
  gap: var(--indent-l);

  > * {
    user-select: none;
    max-width: 366px;
    flex-shrink: 0;
    width: 100%;
  }
}
