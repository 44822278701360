.hero-slider {
  @include flex-stretch-between;
  gap: var(--indent-l);

  @include tablet {
    height: 400px;
  }

  @include small-tablet {
    height: 300px;
  }

  @include mobile {
    flex-direction: column;
    gap: var(--indent-s);
  }

  &__gallery-thumbs {
    width: 82px;
    flex-shrink: 0;

    @include mobile {
      width: 100%;
    }

    .swiper-slide {
      width: 82px;
      height: 82px;
      overflow: hidden;
      border-radius: var(--indent-l);

      @include mobile {
        width: 62px;
        height: 62px;
        border-radius: var(--indent-s);
      }

      img {
        @extend %img-cover;
      }
    }
  }

  &__gallery-main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--indent-l);

    @include small-tablet {
      border-radius: var(--indent-m);
    }

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        @extend %img-cover;
      }
    }
  }
}
