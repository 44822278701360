.grid4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--indent-l);

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include mobile {
    grid-template-columns: 1fr;
  }
}
