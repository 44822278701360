.personalized-solutions {
  @include flex-column;
  border-radius: var(--indent-s);
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;

  > * {
    color: #1b1b1b;
  }

  &__img {
    width: 100%;
    height: 258px;

    @include mobile {
      height: auto;
    }

    img {
      @extend %img-cover;
    }
  }

  &__text {
    @include flex-column;
    gap: var(--indent-m);
    padding: var(--indent-l);

    @include mobile {
      padding: var(--indent-s);
    }
  }
}
