.section-container {
  @extend .container;
  @include flex-column;
  gap: var(--indent-xl);

  @include small-tablet {
    gap: var(--indent-l);
  }

  &--text {
    @include flex-align-start;

    p {
      @extend .text;
    }
  }
}
