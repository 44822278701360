.dashboard-chat {
  @include flex-stretch-between;
  width: 100%;
  height: 650px;
  border-radius: var(--indent-s);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
  position: sticky;
  top: 20px;

  &__chat {
    display: none;
    position: relative;
    @include before {
      width: 2px;
      height: 100%;
      background-color: var(--color-grey);
      position: absolute;
      z-index: 0;
      left: -2px;
      top: 0;

      @include large-tablet {
        display: none;
      }
    }

    @include large-tablet {
      display: none;

      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &._active {
      display: flex;
    }

    &._show {
      display: flex;

      @include large-tablet {
        display: none;
      }
    }
  }
}

.chat-dialogs {
  @include flex-column;
  @include hide-scroll;
  width: 400px;
  flex-shrink: 0;
  overflow-y: auto;
  position: relative;

  @include tablet {
    flex-shrink: 1;
  }

  @include large-tablet {
    width: 100%;
  }
}

.chat-dialog {
  @include flex-stretch-between;
  gap: var(--indent-s);
  padding: var(--indent-s) var(--indent-m);
  cursor: pointer;
  border-left: 4px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transition: background-color 0.25s ease;
  position: relative;
  z-index: 2;

  &:hover {
    background-color: var(--color-grey);
  }

  &._active {
    background-color: #ffffff;
    border-left: 4px solid var(--color-primary);
    border-top: 2px solid var(--color-primary-hover);
    border-bottom: 2px solid var(--color-primary-hover);
    border-right: 2px solid var(--color-primary-hover);

    .chat-name,
    .chat-dialog-message {
      color: #1b1b1b;
    }

    &:hover {
      background-color: #ffffff;
    }

    &:nth-child(1) {
      border-top-left-radius: var(--indent-s);
      border-top-right-radius: var(--indent-s);
    }

    &:nth-last-child(1) {
      border-bottom-left-radius: var(--indent-s);
      border-bottom-right-radius: var(--indent-s);
    }
  }
}

.chat-avatar {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  position: relative;

  @include before {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: var(--color-grey-dark);
  }

  &._online {
    @include before {
      background-color: var(--color-primary-hover);
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}

.chat-text {
  @include flex-column;
  flex: 0 1 100%;
  overflow: hidden;
}

%chat-text {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-name {
  @extend %chat-text;
  @extend .text-bold;
}

.chat-dialog-message {
  @extend %chat-text;
  @extend .text;
}

.chat-dialog-status {
  @extend %chat-text;
  @extend .text;
  color: var(--color-grey-dark);
}

.chat-info {
  @include flex-column;
  @include flex-align-center;
  flex-shrink: 0;
}

.chat-date {
  @extend .text;
  color: var(--color-grey-dark);
}

.chat-status {
  width: 24px;
  height: 24px;
  background-color: var(--color-grey-dark);
  mask-image: url("@img/icons/check.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;

  &._read {
    background-color: var(--color-primary-hover);
  }
}

.chat {
  @include flex-stretch-between;
  @include flex-column;
  flex: 0 1 100%;
  background-color: #ffffff;

  &__header {
    @include flex-center-between;
    padding: 14px var(--indent-m);
    gap: var(--indent-s);
    background-color: var(--color-grey);

    .chat-name {
      color: #1b1b1b;
    }
  }

  &__header-btns {
    @include flex-center-end;
    gap: var(--indent-m);
    flex-shrink: 0;
  }

  &__content {
    @include flex-column;

    @include hide-scroll;
    gap: var(--indent-s);
    height: 100%;
    overflow-y: auto;
    padding: 48px 30px;

    @include mobile {
      padding: 24px 15px;
    }
  }
}

.chat-message {
  @include flex-column;

  gap: 4px;
  padding: var(--indent-s);
  max-width: calc(50% - var(--indent-s));
  background-color: var(--color-grey);
  border-radius: 0 var(--indent-s) var(--indent-s) var(--indent-s);

  @include small-tablet {
    max-width: 70%;
  }

  &__text {
    @extend .text;
    color: #1b1b1b;
  }

  &__info {
    @include flex-center-between;
    gap: var(--indent-s);
  }

  &__time {
    @extend .text-small;
    width: 100%;
    text-align: right;
    color: var(--color-grey-dark);
  }

  &._answer {
    margin-left: auto;
    text-align: right;
    border-radius: var(--indent-s) 0 var(--indent-s) var(--indent-s);
    background-color: var(--color-light-green);
  }
}

%chat-call {
  @include btn-reset;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: #1b1b1b;
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: var(--color-primary);
  }
}

.video-call {
  @extend %chat-call;
  mask-image: url("@img/icons/videocam.svg");
}

.voice-call {
  @extend %chat-call;
  mask-image: url("@img/icons/phone.svg");
}

.chat-form {
  @include flex-stretch-between;

  &__textarea {
    @extend .text;
    @include input-reset;
    padding: var(--indent-s) var(--indent-m);
    width: 100% !important;
    resize: none !important;
    height: 52px !important;
    outline: none;
    border-top: 2px solid var(--color-grey);
    border-right: 2px solid var(--color-grey);
    background-color: #ffffff;

    &::placeholder {
      @extend .text;
      color: var(--color-grey-dark);
    }
  }

  &__button {
    @include btn-reset;
    @include flex-all-center;
    @extend .text;
    border-radius: 0 12px 12px 0;
    line-height: 1;
    gap: 10px;
    color: var(--color-grey-dark);
    padding: 14px 12px 10px 12px;
    background-color: var(--color-light-green);
    transition:
      background-color 0.25s ease,
      color 0.25s ease;
    &:hover {
      background-color: var(--color-primary-dark);
      color: #ffffff;
    }

    @include before {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      transform: translateY(-2px);
      background-color: currentColor;
      mask-image: url("@img/icons/plus.svg");
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;
    }
  }
}

.back-btn {
  @extend %chat-call;
  mask-image: url("@img/icons/arrow-back.svg");
  &._hide {
    display: none;

    @include large-tablet {
      display: flex;
    }
  }
}
