.list-info {
  @include flex-all-center;
  gap: var(--indent-l);
  width: 100%;
  position: relative;

  &__info {
    position: absolute;
    left: 0;
    top: 0;

    @include tablet {
      display: none;
    }
  }

  &__pagination {
    @include small-tablet {
      display: none;
    }
  }

  &__mobile-pagination {
    @extend .btn;
    display: none;

    @include small-tablet {
      display: flex;
    }
  }
}
