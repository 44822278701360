@mixin big-desktop {
  @media (max-width: 1480px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1240.1px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1240px)) {
    @content;
  }
}

@mixin large-tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (580px)) {
    @content;
  }
}

@mixin little-mobile {
  @media (max-width: (375px)) {
    @content;
  }
}
