.custom-select {
  position: relative;

  &._open {
    .custom-select__list {
      pointer-events: auto;
      max-height: 500px;
    }

    .custom-select__btn::after {
      transform: rotate(-180deg);
    }
  }

  &__btn {
    @include flex-center-between;
    @include btn-reset;
    @extend .text;
    gap: var(--indent-l);
    background-color: #fff;
    padding: 10px;
    border: 3px solid var(--color-primary);
    border-radius: 12px;

    &::after {
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("@img/icons/select-arrow.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      transition: transform 0.25s ease;
    }
  }

  &__list {
    @include flex-column;
    max-height: 0;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 3;
    border-radius: var(--indent-s);
    background-color: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

    transition: max-height 0.25s ease;

    > * {
      @include first {
        padding-top: var(--indent-s);
      }

      @include last {
        padding-bottom: var(--indent-s);
      }
    }
  }

  &__link {
    @include text-decoration-none;
    @include flex-center-start;
    @extend .text-bold;
    padding: var(--indent-s) var(--indent-l);
    color: var(--color-black);
    gap: 8px;
    overflow: hidden;

    &:hover {
      background-color: var(--color-light-green);
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateY(2px);
    }

    img {
      flex-shrink: 0;
    }
  }
}
