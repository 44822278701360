.coll2 {
  @include flex-stretch-between;
  gap: var(--indent-l);

  @include tablet {
    flex-direction: column;
  }

  &__item {
    @include flex-column;
    @include flex-align-start;
    gap: var(--indent-xl);
    width: calc(50% - var(--indent-l) / 2);

    @include tablet {
      width: 100%;
    }

    &--img {
      overflow: hidden;
      border-radius: var(--indent-s);

      picture,
      img {
        @extend %img-cover;
      }
    }
  }
}
