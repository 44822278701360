%item-setting {
  padding: var(--indent-l);
  border-radius: var(--indent-xs);

  @include mobile {
    padding: var(--indent-l) var(--indent-xs);
  }
}

.text-item {
  @include flex-column;
  @include flex-align-start;
  width: 100%;
  gap: var(--indent-l);

  &._border {
    @extend %item-setting;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
    background-color: #fff;

    > *:not(._primary) {
      color: #1b1b1b;
    }

    > .btn {
      color: #fff;
    }
  }

  &._border-green {
    @extend %item-setting;
    border: 1px solid var(--color-primary);
  }

  &._grey {
    @extend %item-setting;
    background-color: var(--color-grey);

    > *,
    li {
      color: #1b1b1b;
    }

    > .btn {
      color: #fff;
    }
  }

  &._white {
    @extend %item-setting;
    background-color: #fff;

    > *,
    li {
      color: #1b1b1b;
    }

    > .btn {
      color: #fff;
    }
  }

  &._green {
    @extend %item-setting;
    background-color: var(--color-light-green);
    h1,
    h2,
    h3,
    p,
    li {
      color: #1b1b1b;
    }
  }

  &._red {
    @extend %item-setting;
    background-color: var(--color-pink);
    h1,
    h2,
    h3,
    p,
    li {
      color: #1b1b1b;
    }
  }

  &._full {
    @include flex-justify-between;
  }

  &._center {
    @include flex-all-center;
  }

  &._align-center {
    @include flex-start-center;
  }

  &._img-center {
    picture {
      display: block;
    }
    picture,
    img {
      margin: 0 auto;
    }
  }

  p {
    @extend .text;
  }

  .end {
    margin-left: auto;
  }
}
