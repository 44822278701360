.every-aspect {
  @include flex-column;
  gap: var(--indent-l);
  padding: var(--indent-l) var(--indent-xl);
  border-radius: var(--indent-s);
  background-color: #1b1b1b;

  img {
    margin: 0 auto;
  }

  > * {
    color: #ffffff;
  }
}
