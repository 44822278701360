.main-wait {
  position: relative;
  overflow: hidden;

  &__reg-form {
    position: relative;
    z-index: 1;
  }

  &__gigo-bottom {
    position: fixed;
    z-index: 0;
    bottom: -80px;
    right: 50px;

    @include mobile {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: initial;
      width: 100%;
      right: initial;
    }
  }
}
