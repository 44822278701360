.footer {
  padding: var(--indent-xl) 0;
  background-color: var(--color-grey);

  > * {
    color: #1b1b1b;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--indent-l);

    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }

  &__menu-title {
    @include small-tablet {
      display: flex;
      align-items: center;
      gap: 8px;

      &::after {
        content: "";
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        background-image: url("@img/icons/select-arrow.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }

  &__nav {
    @include flex-column;
    gap: var(--indent-l);

    @include small-tablet {
      gap: var(--indent-s);
      &._open {
        .footer__menu {
          pointer-events: auto;
          max-height: 1000px;
        }
      }
    }
  }

  &__menu {
    @include flex-column;
    gap: var(--indent-l);

    @include small-tablet {
      gap: var(--indent-s);
      max-height: 0px;
      pointer-events: none;
      overflow: hidden;
    }
  }

  &__setting {
    @include flex-column;
    gap: var(--indent-xl);

    @include small-tablet {
      gap: var(--indent-s);
    }
  }

  &__color-theme {
    @include flex-center-between;

    @include tablet {
      flex-wrap: wrap;
    }

    .toggle-btn__content {
      background-color: #fff;
    }

    .toggle-btn__content {
      flex-shrink: 0;
    }
  }

  &__select {
    @include flex-column;
    @include flex-align-start;
    gap: var(--indent-l);

    .custom-select__btn {
      border: initial;
      background-color: transparent;
      padding: 0;
    }

    .custom-select._open .custom-select__btn::after {
      transform: rotate(-180deg);
    }

    /*.select {
      background-color: transparent;
      border: 0;
      outline: 0;
      border-radius: 0;
      transform: translateX(-20px);
      cursor: pointer;

      @include tablet {
        font-size: rem(16);
      }

      &:focus {
        box-shadow: none;
      }
    }*/
  }

  &__apps {
    @include flex-column;
    gap: var(--indent-l);
  }

  &__social {
    display: grid;
    grid-template-columns: repeat(3, 24px);
    gap: 24px;

    @include small-tablet {
      margin-bottom: 12px;
    }
  }

  &__social-link {
    display: block;
    width: 24px;
    height: 24px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__copyright {
    @include flex-all-center;
    @extend .text;
    width: 100%;
    text-align: center;
    padding: var(--indent-2xl) var(--indent-l);
  }
}
