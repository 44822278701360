/*base-styles*/
@import "./vendor/reset";
@import "placeholders";
@import "mixins";
@import "fonts";
@import "vars";
@import "settings";
@import "typography";
@import "btns";

//@import "../../node_modules/swiper/swiper-bundle";

/*страницы регмтсрации и добавления бизнеса*/
@import "./components/reg-header";
@import "./components/reg-form";
@import "./components/custom-checkbox";
@import "./components/custom-radio";
@import "./components/toggle-btn";

/*главная страница*/
@import "./components/header";
@import "./components/menu-link";
@import "./components/hero";
@import "./components/search-future";

/*Шапка авторизованного*/
@import "./components/notification";
@import "./components/notification-item";
@import "./components/header-account";
@import "./components/header-btn";
@import "./components/header-search";

/*dashboard*/
@import "./components/dashboard-hero";
@import "./components/main-dashboard";
@import "./components/sidebar-menu";
@import "./components/rating";
@import "./components/hero-slider";
@import "./components/dashboard-map";
@import "./components/dashboard-contact";
@import "./components/grid3-min";
@import "./components/grid2-min";

@import "./components/dashboard-orders";
@import "./components/distance";
@import "./components/order-item";
@import "./components/dashboard-block";
@import "./components/review-small";
@import "./components/photos-block";

/*dashboard chat*/
@import "./components/dashboard-chat";

/*dashboard service*/
@import "./components/dashboard-text";

/*dashboard billing*/
@import "./components/text-item";
@import "./components/check-list";
@import "./components/pro";
@import "./components/price";
@import "./components/for-pro";
@import "./components/disabled-block";
@import "./components/order-for-pro";

@import "./components/order-item";

/*orders page*/
@import "./components/grid1";
@import "./components/show-more";

/*order-page*/
@import "./components/order-header";
@import "./components/order-content";
@import "./components/order-photos";
@import "./components/order-contact";
@import "./components/order-completion";
@import "./components/order-address";
@import "./components/order-chat";

/*report page*/
@import "./components/report-block";

/*find task*/
@import "./components/find-task";
@import "./components/task-search";

/*careers page*/
@import "./components/grey-text";
@import "./components/scroll-block";
@import "./components/every-aspect";
@import "./components/sense-of-belonging";
@import "./components/grid5";

/*who we are*/
@import "./components/gigos-story";
@import "./components/grid6";

/*contacts us*/
@import "./components/operating-mode";
@import "./components/full-img";

/*press*/
@import "./components/select-filter";
@import "./components/latest-news";
@import "./components/list-info";
@import "./components/pagination";

@import "./components/mobile-app";
/*faq*/
@import "./components/faq";
@import "./components/faq-item";

/**/
@import "./components/section";
@import "./components/section-container";
@import "./components/grid3";
@import "./components/popular-project";
@import "./components/star";

/**/
@import "./components/popular-home";

/**/
@import "./components/how-it-work";

/**/
@import "./components/popular-home";

/**/
@import "./components/coll2";

/**/
@import "./components/section-header";
@import "./components/featured-grid";
@import "./components/featured-big";
@import "./components/featured-lable";
@import "./components/featured-date";
@import "./components/featured-min";

@import "./components/latest-new";
@import "./components/latest-info";

/**/
@import "./components/state-map";
@import "./components/map-tooltip";
@import "./components/map-pin";

/**/
@import "./components/personalized-solutions";

/**/
@import "./components/presentation";
@import "./components/presentation-slider";

@import "./components/video";
@import "./components/presentation-links";
@import "./components/email-alerts";
@import "./components/checkbox-coll";

@import "./components/code";
@import "./components/author";

/**/
@import "./components/task-progress";
@import "./components/task-form";
@import "./components/task-info";
@import "./components/task-block";
@import "./components/notification-block";
@import "./components/offers-blank";
@import "./components/offer-author";

@import "./components/custom-grid";

/**/
@import "./components/brands";
@import "./components/form-center";

/**/
@import "./components/partners";

/* не хватало */
@import "./components/share";
@import "./components/list";
@import "./components/hero-about";
@import "./components/grid4";
@import "./components/explore-job";

@import "./components/page404";

/**/
@import "./components/footer";

@import "./components/dark";
