.dashboard-block {
  @include flex-column;
  gap: var(--indent-l);

  border-radius: var(--indent-s);
  background-color: var(--color-white);

  &._grey {
    padding: var(--indent-l);
    background-color: var(--color-grey);

    @include mobile {
      padding: var(--indent-m);
    }
  }

  &._green {
    padding: var(--indent-l);
    background-color: var(--color-light-green);

    @include mobile {
      padding: var(--indent-m);
    }
  }

  &__header {
    @include flex-center-between;
    gap: var(--indent-l);
  }
}
