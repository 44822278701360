:root {
  // base
  --font-family: "IstokWeb", sans-serif;
  --content-width: 1248px;
  --container-offset: 15px;

  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --color-bg: #ffffff;
  --color-white: #ffffff;
  --color-black: #1b1b1b;
  --color-grey: #f5f4f2;
  --color-pink: #ffe2e2;
  --color-red: #ee7777;
  --color-grey-dark: #a5a5a5;
  --color-light-green: #e2ffd8;
  --color-primary: #33cc00;
  --color-primary-dark: #076600;
  --color-primary-hover: #5ff140;

  //indents
  --indent-xs: 8px;
  --indent-s: 12px;
  --indent-m: 16px;
  --indent-l: 24px;
  --indent-lx: 32px;
  --indent-xl: 48px;
  --indent-2xl: 64px;

  //sizes
  --burger-width: 36px;
  --burger-height: 26px;
  --burger-line-height: 3px;

  --header-height: 127px;

  @include little-mobile {
    --container-offset: 10px;
  }
}
