@import "../../../node_modules/lightgallery/css/lightgallery";

.order-photos {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-flow: var(--indent-m);
  gap: var(--indent-m);

  @include small-tablet {
    grid-template-columns: repeat(6, 1fr);
    gap: var(--indent-s);
  }

  @include mobile {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    width: 100%;
    height: 100%;
    border-radius: var(--indent-s);
    overflow: hidden;
    cursor: pointer;

    img {
      @extend %img-cover;
      transition: transform 0.25s linear;
    }

    @include first {
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      @include mobile {
        grid-column: 1 / 4;
        grid-row: 1 / 4;
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }
  }
}

.lg-components {
  display: none;
}

.lg-prev {
  &::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    background-color: #fff;
    mask-image: url("@img/icons/slide-arrow.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
  }
}

.lg-next {
  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-color: #fff;
    mask-image: url("@img/icons/slide-arrow.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
  }
}

.lg-close {
  &::after {
    content: "" !important;
    display: block;
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    background-color: #fff;
    mask-image: url("@img/icons/close.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
  }
}

.lg-icon {
  font-family: initial !important;
  transition: opacity 0.25s ease;
  background-color: transparent;
  &:hover {
    opacity: 0.7;
  }
}

.lg-backdrop {
  background-color: rgba(#1b1b1b, 0.5);
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: initial !important;
}

.lg-image {
  border-radius: 12px;
  border: 32px solid #fff;
}

.lg-counter {
  color: #fff;
}
