.rating {
  @include flex-center-start;
  gap: 4px;

  &._min {
    gap: 2px;

    .rating__star {
      width: 24px;
      height: 24px;
    }
  }

  &__star {
    width: 34px;
    height: 34px;
    background-image: url("@img/icons/star.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
