.for-pro {
  @include flex-all-center;
  @extend .text-bold;
  gap: var(--indent-xs);
  padding: var(--indent-xs);
  border-radius: var(--indent-xs);
  background-color: var(--color-light-green);
  color: #1b1b1b;
  .pro {
    &::before {
      transform: translateY(-50%);
    }
  }
}
