.pagination {
  @include flex-all-center;
  gap: var(--indent-l);

  &__btn {
    @include flex-all-center;
    @extend .text-bold;
    @include btn-reset;
    @include text-decoration-none;
    gap: var(--indent-s);
    color: var(--color-black);

    svg {
      path {
        transition: fill 0.25s ease;
        fill: currentColor;
      }
    }
  }
}
