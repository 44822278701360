.mobile-app {
  @include flex;

  &__btns {
    @include flex-stretch-between;
    @include flex-column;
    gap: var(--indent-s);
    padding: var(--indent-m);
  }
}
