.offers-blank {
  @include flex-all-center;
  @extend .text-bold;
  color: var(--color-grey-dark) !important;
  width: 100%;
  min-height: 205px;
  padding: var(--indent-l);
  border-radius: var(--indent-s);
  border: 2px solid var(--color-primary);
}
