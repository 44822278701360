.toggle-btn {
  position: relative;
  display: inline-block;
  width: 82px;
  height: 38px;
  cursor: pointer;

  &__field {
    display: none;

    &:checked + .toggle-btn__content::after {
      opacity: 0;
    }

    &:checked + .toggle-btn__content {
      background-color: var(--color-primary);
    }

    &:checked + .toggle-btn__content:before {
      transform: translateX(-47px);
      background-color: var(--color-white);
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey);

    transition: 0.4s;
    border-radius: 20px;

    &::before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      border-radius: 50%;
      right: 3px;
      bottom: 5px;
      background-color: var(--color-primary);
      transition: transform 0.25s ease;
    }

    &:after {
      @extend %text;
      content: "Off";
      font-size: rem(20);
      position: absolute;
      left: 10px;
      top: 8px;
      transition: opacity 0.25s ease;
    }
  }
}
