.featured-big {
  @include flex-column;
  gap: var(--indent-l);

  &__img {
    width: 100%;
    height: 400px;
    border-radius: var(--indent-s);
    overflow: hidden;

    @include tablet {
      height: auto;
    }

    img {
      @extend %img-cover;
    }
  }

  &__info {
    @include flex-center-between;
    gap: var(--indent-l);
  }
}
