.star {
  @include flex-center-start;
  gap: 5px;
  &::before {
    content: "";
    width: 24px;
    height: 24px;
    transform: translateY(-2px);
    background-color: var(--color-primary-hover);
    mask-image: url("@img/icons/star.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
  }
}
