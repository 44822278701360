%text {
  font-family: var(--font-family);
  font-style: normal;
  line-height: 1.2;
}

.main-title {
  @extend %text;
  font-size: rem(64);
  font-weight: 700;

  @include small-tablet {
    font-size: rem(48);
  }
}

.second-title {
  @extend %text;
  font-size: rem(48);
  font-weight: 700;

  @include small-tablet {
    font-size: rem(36);
  }
}

.third-title {
  @extend %text;
  font-size: rem(36);
  font-weight: 700;

  @include small-tablet {
    font-size: rem(20);
  }
}

.big-24 {
  @extend %text;
  font-size: rem(24);

  @include small-tablet {
    font-size: rem(20);
  }
}

.big-24-bold {
  @extend .big-24;
  font-weight: 700;
}

.text {
  @extend %text;
  font-size: 20px;

  @include small-tablet {
    font-size: rem(14);
  }
}

a {
  text-decoration: none;
  color: currentColor;
  transition: color 0.25s ease;

  b {
    font-weight: 700;
  }

  &:hover {
    color: var(--color-primary);
  }

  &.primary {
    color: var(--color-primary-dark);

    &:hover {
      color: var(--color-black);
    }
  }

  &:hover {
    color: var(--color-primary);
  }
}

b,
strong {
  font-weight: 700;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.text-bold {
  @extend .text;
  font-weight: 700;
}

.text-small {
  @extend %text;
  font-size: 12px;
}

a {
  color: currentColor;
  text-decoration: none;

  &.primary {
    color: var(--color-primary-dark);

    &:hover {
      color: var(--color-black);
    }
  }
}

.link {
  text-decoration: none;
  color: currentColor;
  transition: color 0.25s ease;

  &._primary {
    &:hover {
      color: var(--color-primary-hover);
    }
  }

  &._bottom {
    margin-top: auto;
  }

  &:hover {
    color: var(--color-primary);
  }
}

.big-link {
  word-break: break-all;
}

.link-primary {
  @include text-decoration-none;
  color: var(--color-primary-dark);
  transition: color 0.25s ease;

  &:hover {
    color: var(--color-black);
  }
}

.main-bl {
  @include flex-column;
  gap: var(--indent-l);
  padding-top: var(--indent-2xl);
  padding-bottom: var(--indent-2xl);
}

.list {
  @include flex-column;
  gap: var(--indent-m);
  > li {
    @extend .text;
  }
}

.num-list {
  @include flex-column;
  gap: var(--indent-l);
  counter-reset: numList;

  > li {
    @extend .text;

    ul {
      margin-top: var(--indent-m);
    }

    a {
      @extend .link;
    }

    &:before {
      color: var(--color-primary);
      counter-increment: numList;
      content: counter(numList) ". ";
    }
  }
}

._primary {
  color: var(--color-primary-dark);
}

._grey-text {
  color: var(--color-grey-dark);
}

.new {
  @extend .text-small;
  color: var(--color-primary);
}

.update {
  @extend .text-small;
  color: var(--color-red);
}

._dark {
  color: #1b1b1b;
}

hr {
  width: 100%;
  height: 2px;
  background-color: var(--color-grey-dark);
}
