:root[theme="dark"] {
  --color-bg: #1b1b1b;
  --color-white: #1b1b1b;
  --color-black: #ffffff;
}

[theme="dark"] {
  .logo {
    &__color {
      fill: #ffffff;
    }
  }

  .apple-fill {
    fill: #ffffff;
  }

  .custom-select__link img,
  .header__btn img {
    display: none;
  }

  .toggle-btn__content:after {
    color: #1b1b1b;
  }

  .dashboard-contact {
    *:not(.btn) {
      color: #1b1b1b;
    }
  }

  .sidebar-menu__link svg path {
    stroke: #ffffff;
  }

  .sidebar-menu__link._active {
    color: #1b1b1b;

    svg path {
      stroke: #1b1b1b;
    }

    @include mobile {
      color: #ffffff;

      svg path {
        stroke: #ffffff;
      }
    }
  }

  .chat-dialog:hover {
    background-color: transparent;
  }

  .chat-dialog._active:hover {
    background-color: #ffffff;
  }

  .order-item {
    * {
      color: #1b1b1b;
    }

    .btn {
      color: #fff !important;
    }

    .btn--report {
      color: #1b1b1b !important;
    }
  }

  .dashboard-orders__header {
    * {
      color: #1b1b1b;
    }
  }

  .dashboard-block__header {
    color: #1b1b1b;
  }

  .header-account__container {
    background-color: #2b2a2a;
  }

  .header-btn {
    color: #ffffff;

    @include hover {
      color: var(--color-primary);
    }
  }

  .notification__all {
    color: #ffffff;
    @include hover {
      color: var(--color-primary);
    }
  }

  .header__btns {
    .header-btn {
      &._account {
        color: #ffffff;

        &:hover {
          color: var(--color-primary);
        }

        @include tablet {
          color: #ffffff;
        }
      }

      svg {
        path {
          stroke: #ffffff;
        }
      }

      &:hover {
        svg {
          path {
            stroke: var(--color-primary);
          }
        }
      }
    }
  }
}
