.task-form {
  &__step {
    @include flex-column;
    @include flex-align-start;
    gap: var(--indent-xl);
    display: none;

    @include tablet {
      gap: var(--indent-m);
    }

    &._show {
      display: flex;
    }
  }

  &__input {
    width: 100%;
  }

  &__info {
    color: var(--color-grey-dark);
  }

  &__group {
    @include flex-column;
    gap: var(--indent-s);
    width: 100%;

    @include tablet {
      flex-direction: column;
    }
  }

  &__row {
    @include flex-stretch-between;
    width: 100%;
    gap: var(--indent-l);

    @include tablet {
      flex-direction: column;
    }
  }

  &__btns {
    @include flex-center-start;
    @include flex-wrap;
    gap: var(--indent-s);
    width: 100%;

    @include tablet {
      margin-top: var(--indent-l);
    }

    @include mobile {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    border-radius: var(--indent-s);
    overflow: hidden;

    min-height: 400px;

    > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
