@import "./components/custom-select";
@import "./components/burger";

.header {
  margin-top: 40px;
  margin-bottom: 50px;
  position: relative;
  z-index: 99;

  @include tablet {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &__logo {
    @include mobile {
      svg {
        width: 100px;
        object-fit: contain;
      }
    }
  }

  &__container {
    @extend .container;
    @include flex-center-between;
    position: relative;
    gap: var(--indent-l);
    padding-top: var(--indent-l);
    padding-bottom: var(--indent-l);
  }

  &__color-theme {
    display: none;

    @include tablet {
      order: 99;
      width: 100%;
      margin-top: auto;
      display: flex;
      align-items: center;
      gap: var(--indent-l);
    }
  }

  &__btns {
    @include flex-center-end;
    gap: var(--indent-s);
    margin-left: auto;

    @include tablet {
      > * {
        justify-content: flex-start;
        flex-shrink: 0;
      }
    }

    &._gap-l {
      gap: var(--indent-l);
    }

    .btn--text {
      padding-left: var(--indent-l);
      padding-right: var(--indent-l);
    }

    @include tablet {
      display: none;
      transition: opacity 0.25s ease;

      align-items: flex-start;
      justify-content: flex-start;
      gap: var(--indent-l);
      flex-direction: column;
      position: fixed;
      z-index: 99;
      padding: var(--indent-l);
      top: var(--header-height);
      left: 0;
      width: 100%;

      height: calc(var(--vh) * 100 - var(--header-height));
      overflow-y: auto;
      background-color: var(--color-white);

      > * {
        width: 100%;
      }

      &._show {
        display: flex;
        animation: menuShow 0.25s ease both;
      }

      &._close {
        display: flex;
        animation: menuClose 0.25s ease both;
      }

      .custom-select {
        border-top: 3px solid var(--color-grey);
        border-bottom: 3px solid var(--color-grey);
        padding: var(--indent-l) 0;
      }

      .custom-select__btn {
        display: none;
      }

      .custom-select__list {
        max-height: initial;
        pointer-events: auto;
        position: relative;
        padding: 0;
        top: initial;
        box-shadow: initial;
        gap: var(--indent-l);
      }

      .custom-select__link {
        padding: 0;
        font-size: rem(20);
      }

      ._o1 {
        order: 1;
      }

      ._o2 {
        order: 2;
      }

      ._o3 {
        order: 3;
      }

      ._o4 {
        order: 4;
      }
    }
  }

  &__btn {
    img {
      display: none;

      @include tablet {
        display: block;
        transform: translateY(-2px);
      }
    }

    @include tablet {
      display: flex;
      align-items: center;
      font-size: 20px;
      gap: 8px;
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent;
      color: var(--color-black);
    }
  }

  &__nav {
    @extend .container;
  }

  &__menu {
    @include flex-center-start;
    @include flex-wrap;
    gap: var(--indent-xl);

    @include tablet {
      gap: var(--indent-l);
    }

    li {
      position: relative;

      &:hover {
        .menu-link--sub::after {
          transform: rotate(-180deg);
        }
        > a {
          color: var(--color-primary);
        }
        .header__sub-menu {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &__sub-menu {
    @include flex-column;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    min-width: 300px;
    top: 100%;
    left: 0;
    z-index: 3;
    border-radius: var(--indent-s);
    background-color: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

    transition: opacity 0.25s ease;

    @include tablet {
      display: none;
    }

    > * {
      padding-left: var(--indent-l);
      padding-right: var(--indent-l);

      @include first {
        padding-top: var(--indent-s);
      }

      @include last {
        padding-bottom: var(--indent-s);
      }
    }
  }
  &__search {
    transition: opacity 0.25s ease;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 99;
    right: 0;
    top: 50%;
    width: calc(100% - 200px);
    transform: translateY(-50%);

    @include tablet {
      top: 120px;
      left: var(--container-offset);
      transform: translateY(0);
      width: calc(100% - var(--container-offset) * 2) !important;
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__search-btn {
    @extend %btn-reset;
    transform: translateY(3px);
    transition: opacity 0.25s ease;

    @include tablet {
      width: 36px;
      height: 36px;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &.no-indent {
      transform: translateY(0px);
    }

    @include hover {
      opacity: 0.7;
    }

    @include tablet {
      margin-left: auto;
    }
  }
}

@keyframes menuShow {
  0% {
    left: -200%;
  }

  100% {
    left: 0;
  }
}
@keyframes menuClose {
  0% {
    left: 0;
  }

  100% {
    left: -200%;
  }
}
