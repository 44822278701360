.search-future {
  @include flex-column;
  gap: 8px;

  @include tablet {
    width: 100%;
  }

  &__line {
    @include flex-stretch-between;

    @include tablet {
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;
    position: relative;

    &:nth-child(1) {
      .search-future__input {
        border-radius: 12px 0px 0px 12px;

        @include tablet {
          border-radius: 12px 12px 0px 0px;
        }
      }
    }

    &:nth-child(2) {
      .search-future__input {
        transform: translateX(-3px);
        @include tablet {
          transform: translateX(0px);
          transform: translateY(-3px);
        }
      }
    }
  }

  &__input {
    @extend .text;
    @include input-reset;
    width: 100%;
    padding: var(--indent-l) var(--indent-l) 18px;
    border: 3px solid var(--color-light-green);
    background-color: #fff;
    outline: none;
    transition: border 0.25s ease;
    position: relative;

    &:focus {
      border-color: var(--color-primary);
      z-index: 2;
    }

    &::placeholder {
      @extend .text;
      color: var(--color-grey-dark);
    }
  }

  &__btn {
    height: auto;
    flex-shrink: 0;
    padding-left: 120px !important;
    padding-right: 120px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    transform: translateX(-3px);

    @include tablet {
      width: 100%;
      padding-left: 20px !important;
      padding-right: 20px !important;
      transform: translateX(0px);
      transform: translateY(-3px);
      border-radius: 0px 0px 12px 12px !important;
    }
  }

  &__container {
    @include flex-column;
    gap: var(--indent-xs);
    min-width: 100%;
    width: auto;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    max-height: 270px;
    overflow-y: auto;
    padding: var(--indent-s) var(--indent-l);
    border-radius: var(--indent-s);
    background-color: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
    transition: opacity 0.25s ease;
    opacity: 0;
    pointer-events: none;
    text-overflow: ellipsis;

    @include tablet {
      width: 100%;
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: var(--color-grey);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 12px;
    }
  }

  &__item-btn {
    @include flex-center-start;
    @extend .text;
    padding: 4px 8px;
    gap: var(--indent-s);
    font-weight: 700;
    transition: color 0.25s ease;
    cursor: pointer;

    @include hover {
      color: var(--color-primary);
    }

    svg {
      transform: translateY(-2px);
      path {
        transition: stroke 0.25s ease;
        stroke: currentColor;
      }
    }
  }

  &__item-address {
    @include flex-center-start;
    @extend .text;
    padding: 4px 8px;
    gap: var(--indent-s);
    transition: color 0.25s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    @include hover {
      color: var(--color-primary);
    }
  }

  &__item-location {
    @include flex-center-start;
    @extend .text;
    padding: 4px 8px;
    gap: var(--indent-s);
    font-weight: 700;
    transition: color 0.25s ease;
    cursor: pointer;

    @include hover {
      color: var(--color-primary);
    }

    svg {
      transform: translateY(-2px);
    }
  }
}
