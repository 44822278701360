html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline-color: var(--color-primary);
  -webkit-tap-highlight-color: transparent;
}

.page {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  overflow: visible;
  position: relative;
  height: 100%;
  font-size: 16px;
  color: var(--color-black);
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  height: 100%;
}

.site-container {
  @extend %flex;
  @extend %flex-column;
  width: 100%;
  min-height: 100%;
  background-color: var(--color-bg);

  /*
  &.center {
    align-items: center;
    justify-content: center;
    height: 100%;

    .logo {
      width: 293px;
      height: 140px;
    }

    .main {
      margin-top: var(--indent-2xl);
      flex: 0 1 auto;
    }

    .main > * {
      min-height: auto;
    }
  }
  */
}

.main {
  @extend %flex;
  @extend %flex-column;
  flex: 1 1 auto;
}

.container {
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--container-offset);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.scroll-content {
  @include scroll;
  overflow-y: hidden;
  overflow-x: auto;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.visually-hidden {
  @include visually-hidden;
}
