.order-header {
  @include flex-center-between;
  gap: var(--indent-l);

  > .btn {
    flex-shrink: 0;
  }

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
  }
}
