.page404 {
  background-color: #1b1b1b;
  position: relative;

  .logo__color {
    fill: #ffffff;
  }

  @include for-desktop {
    .header__btn {
      &:not(._o2) {
        color: #ffffff;
      }
    }

    .header-account:before {
      background-color: #2b2a2a;
    }

    .header-btn {
      color: #ffffff;
      @include hover {
        color: var(--color-primary);
      }
    }

    .header-btn svg path {
      stroke: currentColor;
    }

    .custom-select {
      color: var(--color-black);
    }
  }

  .header__menu > li > .menu-link {
    color: #ffffff;
  }

  &__container {
    @include flex-all-center;
    @include flex-column;
    position: relative;
    z-index: 98;
    width: 100%;
    height: 100%;
    margin-top: -100px;
    height: 100vh;
    min-height: 600px;
    //transform: translateY(-300px);

    @include large-tablet {
      margin-top: -250px;
      height: auto;
      min-height: 600px;
    }

    @include small-tablet {
      margin-top: -400px;
    }
  }

  .burger {
    color: #ffffff;
  }

  &__content {
    @extend .container;
    position: relative;
    display: grid;
    gap: 70px;
    grid-template-columns: 340px auto;

    @include large-tablet {
      grid-template-columns: 1fr;
    }

    @include small-tablet {
      padding-top: 100px;
    }

    @include mobile {
      padding-top: 200px;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: rem(128);
    color: #fff;

    grid-column: 2 / 3;
    grid-row: 2;

    align-content: end;

    @include large-tablet {
      grid-column: 1;
      grid-row: 1;
    }

    @include small-tablet {
      font-size: rem(80);
    }

    @include mobile {
      font-size: rem(70);
    }
  }

  &__descr {
    position: relative;
    z-index: 1;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: rem(36);
    color: #fff;

    grid-column: 1 / 3;
    grid-row: 1;

    @include large-tablet {
      grid-column: 1;
      grid-row: 2;
    }

    @include small-tablet {
      font-size: rem(24);
    }

    @include mobile {
      font-size: rem(18);
    }
  }

  &__migo {
    position: relative;
    z-index: 1;
    grid-column: 1;
    grid-row: 2;

    @include large-tablet {
      grid-column: 1;
      grid-row: 3;
    }
  }

  &__decor-top {
    width: 100%;
    height: 600px;
    opacity: 0.6;
    @include large-tablet {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
  }

  &__decor-bottom {
    width: 100%;
    height: 600px;
    opacity: 0.6;
    transform: rotate(180deg);

    @include large-tablet {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
  }

  &__decor-star {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: -100px;
    right: 0;
  }

  .footer {
    position: relative;
    z-index: 100;
  }
}
