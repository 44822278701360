.faq-item {
  @include flex-column;
  gap: var(--indent-s);
  padding: var(--indent-s) var(--indent-l);
  border-radius: var(--indent-s);
  background-color: var(--color-grey);

  @include mobile {
    padding: var(--indent-s);
  }

  &._white {
    background-color: #fff;

    &._open {
      .faq-item__title {
        color: var(--color-primary);
      }
    }
  }

  &__content {
    @include flex-column;
    gap: var(--indent-xs);
    display: none;

    > * {
      @extend .text;
    }
  }

  &._open {
    .faq-item__content {
      display: flex;
    }

    .faq-item__title::after {
      transform: rotate(0);
    }
  }

  > * {
    color: #1b1b1b;
  }

  &__title {
    @include flex-center-between;
    gap: var(--indent-l);
    cursor: pointer;

    @include after {
      width: 24px;
      height: 24px;
      transition: transform 0.25s ease;
      background-color: currentColor;
      transform: rotate(180deg);
      mask-image: url("@img/icons/arrow-up-simple.svg");
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;

      @include small-tablet {
        width: 20px;
        height: 20px;
      }
    }
  }
}
