.photos-block {
  .swiper-slide {
    max-width: 160px;
  }

  &__item {
    width: 100%;
    height: 160px;
    border-radius: var(--indent-s);
    overflow: hidden;

    img {
      @extend %img-cover;
    }
  }
}
