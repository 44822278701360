.order-content {
  @include flex-column;
  gap: var(--indent-lx);
  padding: var(--indent-l);
  border-radius: 12px;
  border: 2px solid var(--color-grey);
  background-color: #ffffff;

  > * {
    color: #1b1b1b;
  }

  @include small-tablet {
    padding: var(--indent-s);
    gap: var(--indent-s);
  }

  &__info {
    @include flex-column;
    gap: var(--indent-lx);
    padding-bottom: var(--indent-lx);
    border-bottom: 2px solid var(--color-grey);

    @include small-tablet {
      padding-bottom: var(--indent-s);
      gap: var(--indent-s);
    }
  }
}
