.select {
  @extend .input__input;
  padding: 18px 56px 14px 20px;
  line-height: 1;
  position: relative;

  background-image: url("@img/icons/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 6px center;
  background-size: 26px 24px;
}
