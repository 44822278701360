.task-search {
  @include flex-center-between;
  &__input {
    @include input-reset;
    @extend .text;
    line-height: 1;
    flex: 0 1 100%;
    padding: 20px var(--indent-l);
    border-radius: var(--indent-xs) 0px 0px var(--indent-xs);
    border: 3px solid var(--color-light-green);
    background: var(--White, #fff);

    &::placeholder {
      @extend .text;
      line-height: 1;
      color: var(--color-grey-dark);
    }
  }

  &__btn {
    flex-shrink: 0;
    height: 100%;
    padding: var(--indent-l) 120px;
    border-radius: 0 var(--indent-xs) var(--indent-xs) 0;

    @include mobile {
      font-size: 0;
      padding: var(--indent-s);

      &::before {
        content: "";
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        background-color: currentColor;
        mask-image: url("@img/icons/search.svg");
        mask-repeat: no-repeat;
        mask-position: center center;
        mask-size: contain;
      }
    }
  }
}
