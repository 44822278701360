.hero-about {
  @include flex-column;

  position: relative;
  min-height: 640px;
  height: auto;

  @include small-tablet {
    min-height: initial;
    padding: var(--indent-l) 0;
  }

  &__img {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    &._opacity-mobile {
      @include small-tablet {
        opacity: 0.3;
      }
    }

    img {
      @extend %img-cover;
    }
  }

  &__container {
    @include flex-column;
    flex: 1;
    position: relative;
    z-index: 2;
    padding-bottom: var(--indent-xl) !important;

    @include mobile {
      padding-bottom: var(--indent-l) !important;
    }
  }

  &__white {
    padding: var(--indent-xl);
    border-radius: 0px 8px 8px 0px;
    background-color: rgba(255, 255, 255, 0.9);
    margin-top: auto;
    max-width: 590px;

    &._opacity {
      background-color: transparent;
    }

    &._top {
      margin-top: 0;
      border-radius: 0 0 8px 8px;
    }

    > *:not(.btn) {
      color: #1b1b1b;
    }

    @include small-tablet {
      padding: var(--indent-l);
    }

    @include mobile {
      @include small-tablet {
        padding: var(--indent-s);
      }
    }
  }

  &__text {
    padding: var(--indent-2xl);

    &._developers {
      @include flex-center-between;
      gap: var(--indent-2xl);

      @include tablet {
        flex-direction: column;
      }
    }

    @include small-tablet {
      padding: var(--indent-l) var(--indent-m);
    }
  }

  &__input {
    width: 100%;
  }
}
