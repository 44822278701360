%section-text-setting {
  .show-more,
  h2,
  h3,
  p {
    color: #1b1b1b;
  }

  .show-more {
    &:hover {
      color: var(--color-primary);
    }
  }
}

.section {
  padding: var(--indent-2xl) 0;
  background-color: var(--color-bg);
  overflow: hidden;

  @include small-tablet {
    padding: var(--indent-l) 0;
  }

  &--green {
    @extend %section-text-setting;
    background-color: var(--color-light-green);
  }

  &--grey {
    @extend %section-text-setting;
    background-color: var(--color-grey);
  }
}
