.map-tooltip {
  @include flex;
  @include flex-align-end;
  gap: var(--indent-s);
  padding: var(--indent-s);
  border-radius: var(--indent-s);
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
  transition: all 0.1s ease-in-out;
  position: fixed;
  z-index: 99;
  pointer-events: none;

  &__number {
    @extend .big-24-bold;
    transform: translateY(1px);
    color: #1b1b1b;
  }

  &__text {
    @extend .text;
    color: #1b1b1b;
  }
}
