.btn {
  @include flex-all-center;
  @include btn-reset;
  @include text-decoration-none;
  @extend %text;
  gap: var(--indent-xs);
  font-size: rem(24);
  font-weight: 700;
  line-height: 1;
  border-radius: var(--indent-s);
  padding: 14px var(--indent-xl);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-align: center;

  &._bottom {
    margin-top: auto;
  }

  &._big {
    white-space: initial;
  }

  &._small {
    padding: 12px;
  }

  @include small-tablet {
    font-size: rem(20);
    padding: 14px var(--indent-xl) 12px;
  }

  @include mobile {
    padding: 14px var(--indent-l) 12px;
  }

  &--text {
    @extend .link;
  }

  &--small-text {
    font-size: rem(20);
    font-weight: 400;
    transition: color 0.25s ease;
    padding: var(--indent-l) 0;
    color: var(--color-black);

    &:hover {
      color: var(--color-primary);
    }
  }

  &--primary {
    color: #ffffff;
    background-color: var(--color-primary-dark);
    transition:
      background-color 0.25s ease,
      box-shadow 0.25s ease;

    &:hover {
      color: #ffffff;
      background-color: var(--color-primary-hover);
    }

    &:active {
      box-shadow: 0px 6px 8px 5px rgba(0, 0, 0, 0.12) inset;
    }
  }

  &--primary-border {
    background-color: #ffffff;
    color: var(--color-primary-dark);
    padding: 11px var(--indent-xl);
    border: 3px solid var(--color-primary-dark);
    transition:
      background-color 0.25s ease,
      color 0.25s ease,
      border 0.25s ease,
      box-shadow 0.25s ease;

    &:hover {
      background-color: var(--color-light-green);
      color: var(--color-primary-hover);
      border-color: var(--color-primary-hover);
    }

    &:active {
      color: var(--color-primary);
      border-color: var(--color-primary);
      box-shadow: 0px 6px 8px 5px rgba(0, 0, 0, 0.12) inset;
    }
  }

  &--dashboard {
    @include flex-all-center;
    gap: var(--indent-xs);
    font-size: rem(20);
    border-radius: 12px;
    border: 3px solid var(--color-primary);
    color: #1b1b1b;
    background-color: #ffffff;
    padding: 14px 12px 10px !important;
    font-weight: 400;
    transition: border-color 0.25s ease;

    img {
      margin-top: -2px;
    }

    @include small-tablet {
      font-size: 16px;
    }

    &:hover {
      color: #1b1b1b;
      border-color: var(--color-primary-hover);
    }
  }

  &--report {
    @include flex-all-center;
    gap: var(--indent-xs);
    font-size: rem(20);
    border-radius: 12px;
    border: 3px solid var(--color-red);
    color: #1b1b1b;
    background-color: #ffffff;
    padding: 14px 12px 10px !important;
    font-weight: 400;
    transition: color 0.25s ease;

    img {
      margin-top: -2px;
    }

    @include small-tablet {
      font-size: 16px;
    }

    &:hover {
      color: var(--color-red);
    }
  }

  &[disabled],
  &:disabled {
    background-color: var(--color-grey-dark) !important;
    pointer-events: none;
  }
}

.btns {
  @include flex-center-start;
  @include flex-wrap;
  gap: var(--indent-l);
  @include small-tablet {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}

%min-btn {
  @extend .btn;
  @extend .btn--primary;
  padding: 4px 6px !important;
  border-radius: 8px;
  color: #ffffff;

  @include before {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: currentColor;

    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
  }
}

.add-photo-min {
  @extend %min-btn;
  &:before {
    mask-image: url("@img/icons/add-photo.svg");
  }
}

.edit-info-min {
  @extend %min-btn;
  &:before {
    mask-image: url("@img/icons/edit-02.svg");
  }
}

.link-min {
  @extend %min-btn;
  &:before {
    mask-image: url("@img/icons/external-link.svg");
  }
}

.delete-min {
  @extend %min-btn;
  &:before {
    mask-image: url("@img/icons/delete-2.svg");
  }
}
