.faq {
  width: 100%;

  &--full {
    .faq__list {
      max-width: 100%;
    }

    .faq__content {
      box-shadow: initial;
    }
  }

  &__content {
    @include flex-column;
    padding: var(--indent-l);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
    border-radius: var(--indent-xs);
    gap: var(--indent-l);

    @include mobile {
      padding: var(--indent-l) var(--indent-xs);
    }
  }

  &__container {
    @include flex-start-between;
    gap: var(--indent-l);

    @include tablet {
      flex-direction: column;
    }
  }

  &__list {
    @include flex-column;
    max-width: 720px;
    width: 100%;

    gap: var(--indent-s);

    @include tablet {
      max-width: 100%;
    }
  }

  &__item {
    width: 100%;
  }
}
