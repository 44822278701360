@import "../components/input";
@import "../components/input-line";
@import "../components/input-coll";
@import "../components/select";
@import "../components/lds-ring";
@import "../components/main-wait";
@import "../components/reg-account-info";

.reg-form {
  @include flex-all-center;
  @include flex-column;
  @extend .container;
  padding-top: var(--indent-xl);
  max-width: 790px;
  height: 100%;
  min-height: calc(100vh - 127px);
  padding-bottom: 127px;
  //min-height: calc(var(--vh) * 100 - var(--header-height));
  gap: var(--indent-l);

  &--login {
    max-width: 430px;
  }

  &--preloader {
    //padding-bottom: 350px;
    z-index: 2;
    position: relative;

    @include mobile {
      min-height: auto;
    }

    @include mobile {
      padding-bottom: 0;
    }
  }

  > * {
    width: 100%;
  }

  &__btn {
    padding-top: var(--indent-s);
    padding-bottom: var(--indent-s);
  }

  &__preloader {
    @include flex-all-center;
  }

  &__soc-auth {
    @include flex-all-center;
    gap: var(--indent-2xl);
  }

  &__checkbox {
    max-width: 490px;
    margin: 0 auto;
  }

  &__logo {
    @include flex-all-center;
    margin-bottom: var(--indent-xl);
    svg {
      width: 293px;
      height: 140px;

      @include small-tablet {
        width: auto;
        height: auto;

        margin-bottom: var(--indent-l);
      }
    }
  }

  &__hide {
    @include flex-start-between;
    gap: var(--indent-l);
  }

  &__hide-toggle {
    margin-top: var(--indent-s);
    flex-shrink: 0;

    @include small-tablet {
      margin-top: 0;
    }
  }

  &__small-description {
    @include flex-column;
    gap: var(--indent-l);
    max-width: 400px;
    margin: 0 auto;

    @include small-tablet {
      max-width: 100%;
    }
  }

  &__small-text {
    color: var(--color-grey-dark);

    a {
      text-decoration: none;
      color: var(--color-primary);
      transition: color 0.25s ease;

      &:hover {
        color: currentColor;
      }
    }
  }

  &__question {
    @include flex-column;
    width: 100%;
    gap: var(--indent-m);
    padding: var(--indent-l) 0 0 0;
    border-top: 1px solid var(--color-light-green);
  }

  &__preloader {
    margin-bottom: var(--indent-l);
  }

  &__complete {
    @include flex-column;
    gap: var(--indent-m);
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    border: 2px solid var(--color-light-green);
  }

  &__forgot {
    @extend .link;
    @extend .text;
    text-align: right;
    padding: 0;
    font-weight: 700;
  }
}
