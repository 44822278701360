.loader {
  width: 186px;
  height: 186px;
  animation: load3 1.4s infinite linear;
}

/*.loader {
  overflow: hidden;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 186px;
  height: 186px;
  border-radius: 50%;
  background: var(--color-primary);
  background: -moz-linear-gradient(left, var(--color-primary) 10%, rgba(101, 171, 248, 0) 42%);
  background: -webkit-linear-gradient(left, var(--color-primary) 10%, rgba(101, 171, 248, 0) 42%);
  background: -o-linear-gradient(left, var(--color-primary) 10%, rgba(101, 171, 248, 0) 42%);
  background: -ms-linear-gradient(left, var(--color-primary) 10%, rgba(101, 171, 248, 0) 42%);
  background: linear-gradient(to right, var(--color-primary) 10%, rgba(101, 171, 248, 0) 42%);
  position: relative;
  //animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: var(--color-primary);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
*/
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
