.report-block {
  @include flex-column;
  gap: var(--indent-lx);
  padding: var(--indent-l);
  border-radius: var(--indent-m);
  background-color: #fff;
  border: 2px solid var(--color-grey);

  @include mobile {
    padding: var(--indent-m);
  }

  &__input {
    @include input-reset;
    @extend .text-bold;
    padding: var(--indent-m);
    border-radius: var(--indent-m);
    background-color: var(--color-grey);
  }

  &__group {
    @include flex-column;
    gap: var(--indent-m);
  }

  &__label {
    @extend .text;
    color: #1b1b1b;
  }

  &__textarea {
    @include input-reset;
    @extend .text;
    padding: var(--indent-m);
    border-radius: var(--indent-m);
    background-color: var(--color-grey);
    width: 100% !important;
    min-height: 445px;
  }

  &__footer {
    @include flex-start-between;
    gap: var(--indent-l);

    @include small-tablet {
      flex-wrap: wrap;
    }
  }

  &__file-label {
    width: 30px;
    height: 52px;
    flex-shrink: 0;
    background-image: url("@img/icons/report-file.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer;
    margin-right: auto;
    transition: opacity 0.25s ease;

    @include hover {
      opacity: 0.8;
    }
  }

  &__file-include {
    overflow: hidden;
    text-overflow: ellipsis;

    @include small-tablet {
      order: 1;
    }
  }

  &__file-input {
    @include visually-hidden;
  }

  &__file-item {
    @include flex-center-end;
    @extend .text-small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #1b1b1b;
    }
  }

  &__file-remove {
    @include btn-reset;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: #1b1b1b;
    mask-image: url("@img/icons/close.svg");
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: var(--color-primary);
    }
  }
}
