@import "../components/suggestions";

.input {
  @include flex-column;
  gap: var(--indent-xs);
  position: relative;

  &__input {
    @include input-reset;
    @extend %text;
    font-size: rem(20);
    width: 100%;
    padding: 25px 20px 11px;
    background-color: #ffffff;
    border: 1px solid var(--color-primary);
    border-radius: 12px;
    transition: box-shadow 0.25s ease;
    outline: none;

    &.input-error {
      border-color: var(--color-red);
    }

    &.transparent {
      background-color: transparent;
    }

    &--teaxarea {
      resize: vertical;
      min-height: 200px;
    }

    &:focus {
      box-shadow: 0px 0px 0px 1px var(--color-primary);
    }

    &:not(.input__input:placeholder-shown) ~ .input__label,
    &:focus ~ .input__label {
      font-size: 12px;
      top: 9px;
    }

    &.disabled {
      pointer-events: none;
      background-color: var(--color-grey);
    }
  }

  &__label {
    @extend %text;
    font-size: rem(20);
    color: var(--color-grey-dark);
    position: absolute;
    top: 21px;
    left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 40px);

    pointer-events: none;

    transition:
      font-size 0.25s ease,
      top 0.25s ease;
  }

  &__error {
    color: var(--color-red);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
