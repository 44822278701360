.sidebar-menu {
  @include flex-column;

  @include mobile {
    border-top: 3px solid var(--color-grey);
    border-bottom: 3px solid var(--color-grey);
    padding: var(--indent-l) 0;
    gap: var(--indent-l);
  }

  &__link {
    @include flex;
    @include flex-align-center;
    @extend .text-bold;
    gap: 9px;
    padding: 26px 24px 22px 24px;
    border-radius: var(--indent-s);

    @include tablet {
      font-size: 0 !important;
      gap: 0;
    }

    @include mobile {
      padding: 0;
      gap: var(--indent-xs);
      font-size: rem(14) !important;
    }

    svg {
      transform: translateY(-2px);

      path {
        transition: stroke 0.25s ease;
      }
    }

    &._active {
      color: currentColor;
      background-color: var(--color-light-green);

      @include mobile {
        background-color: transparent;
        color: var(--color-primary);

        svg {
          path {
            stroke: var(--color-primary);
          }
        }
      }
    }

    &:not(._active):hover {
      color: var(--color-primary);

      svg {
        path {
          stroke: var(--color-primary);
        }
      }
    }
  }
}
