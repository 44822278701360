.main-dashboard {
  @include flex-stretch-between;
  flex-direction: row;
  gap: var(--indent-l);
  padding-bottom: var(--indent-2xl);

  @include mobile {
    gap: var(--indent-m);
  }

  @include little-mobile {
    gap: var(--indent-s);
  }

  &__sidebar {
    @include flex-column;
    width: 294px;
    flex-shrink: 0;

    @include tablet {
      width: auto;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    @include flex-column;
    gap: var(--indent-l);
    flex: 0 1 100%;
    overflow: hidden;
    position: relative;

    &._overflow {
      overflow: initial;
    }
  }

  &__chat {
    @include flex-column;
    gap: var(--indent-l);
    position: relative;
    width: calc(100% - 96px);

    @include mobile {
      width: 100%;
    }
  }
}
