.presentation {
  @include flex;
  width: 100%;
  overflow: hidden;
  gap: var(--indent-xl);

  @include tablet {
    flex-direction: column;
  }

  &__slider {
    width: 100%;
  }

  &__links {
    width: 100%;
  }

  @include for-desktop {
    &__slider {
      flex: 0 1 60%;
    }

    &__links {
      flex: 0 1 40%;
    }
  }
}
