.operating-mode {
  @include flex-center-between;
  gap: var(--indent-l);
  width: 100%;
  max-width: 548px;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}
