.latest-info {
  @include flex-center-start;
  gap: var(--indent-xs);

  &__img {
    width: 58px;
    height: 58px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: var(--indent-xs);

    img {
      @extend %img-cover;
    }
  }

  &__content {
    @include flex-column;
  }
}
