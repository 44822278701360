.notification-item {
  @include flex-all-center;
  @include text-decoration-none;
  color: currentColor;
  gap: var(--indent-s);
  background-color: #f5f4f2;
  padding: var(--indent-m);
  transition: background-color 0.25s ease;
  &:hover {
    background-color: #ececeb;
    color: currentColor;
  }

  &__img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;

    img {
      @extend %img-cover;
    }
  }

  &__content {
    flex: 0 1 100%;
  }

  &__title {
    @extend .text-bold;
    color: #1b1b1b;

    &--confirmed {
      color: var(--color-primary);
    }

    &--cancelled {
      color: var(--color-red);
    }
  }

  &__text {
    @extend .text-small;
    color: #1b1b1b;
    &--name {
      color: var(--color-grey-dark);
    }
  }
}
