.brands {
  position: relative;
  width: 100%;
  height: 80px;

  &__container {
    width: 100%;
    padding-bottom: 20px;
    overflow: auto;

    @include for-desktop {
      width: 100vw;
      position: absolute;
      top: 0;
      left: calc(var(--container-width) / 2);
      transform: translateX(-50%);
      cursor: all-scroll;

      &::-webkit-scrollbar {
        width: 0;
        height: 0px;
      }
    }
  }

  &__list {
    @include flex-center-start;
    gap: var(--indent-2xl);
    transform: translateX(0);

    li {
      flex-shrink: 0;
      pointer-events: none;
      user-select: none;
    }
  }
}
