.order-item {
  //@include flex-center-between;
  width: 100%;
  display: grid;
  grid-template-columns: 78px 1fr auto;
  gap: var(--indent-s);
  padding: 20px 24px;
  background-color: #ffffff;
  border-radius: var(--indent-s);
  position: relative;

  &._top {
    .order-item__status {
      margin-top: 0;
    }
  }

  @include small-tablet {
    padding: 10px 12px;
  }

  &:hover {
    color: currentColor !important;
  }

  &._progress {
    background-color: var(--color-light-green);
  }

  &._finished {
    background-color: var(--color-grey);
    height: 100%;
  }

  &._cancelled {
    background-color: var(--color-pink);

    .order-item__status {
      color: var(--color-red);
    }
  }

  &._border {
    border: 2px solid var(--color-grey);
    transition: border 0.25s ease;
    &:hover {
      border-color: var(--color-primary);
    }
  }

  &._no-link {
    .order-item__content {
      &::before {
        display: none;
      }
    }
  }

  &__btn {
    margin-top: var(--indent-l);
  }

  &__preview {
    width: 78px;
    height: 78px;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    border-radius: var(--indent-s);

    img {
      @extend %img-cover;
    }
  }

  &__content {
    @include text-decoration-none;
    @include flex-column;
    @include flex-align-start;
    gap: 4px;
    flex: 1 1 auto;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 1;
      position: absolute;
    }

    &._min {
      &::before {
        display: none;
      }
    }

    &._center {
      justify-content: center;
    }
  }

  &__title {
    @extend .text-bold;
  }

  &__author {
    @extend .text-small;
  }

  &__distance {
    @extend .distance;
  }

  &__info {
    @include flex-stretch-between;
    @include flex-column;
    height: 100%;
    text-align: right;

    &._row {
      flex-direction: row;
      align-items: start;
      gap: var(--indent-s);
    }

    @include mobile {
      grid-column: 1 / 3;
      grid-row: 2;
      text-align: left;
    }
  }

  &__info-row {
    @include flex;
    @include flex-align-start;
    text-align: right;
    gap: var(--indent-s);

    @include small-tablet {
      grid-column: 1 / 3;
      grid-row: 2;
      text-align: left;
    }
  }

  &__info-btn {
    padding: 10px;

    @include small-tablet {
      padding: 10px;
    }

    @include mobile {
      padding: 10px;
    }

    &._report {
      padding: 9px 10px 5px !important;
      line-height: 1;

      svg {
        transform: translateY(-1px);
      }
    }
  }

  &__date {
    @extend .text-small;
    margin-bottom: var(--indent-s);
  }

  &__status {
    @extend .text-bold;
    margin-top: auto;
  }

  &__btns {
    @include flex-center-start;
    @include flex-wrap;
    gap: var(--indent-s);
    grid-column: 2 / 3;
    position: relative;
    z-index: 2;

    @include small-tablet {
      grid-column: 1 / 3;
    }
  }

  &__pro {
    @extend .for-pro;
  }
}
