.pro {
  display: inline-block;
  position: relative;
  width: 24px;
  font-size: 0;

  @include before {
    position: absolute;
    top: 0;
    transform: translateY(-70%);
    left: 0;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-image: url("@img/icons/pro.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @include small-tablet {
      width: 16px;
      height: 16px;
    }
  }

  &--btn {
    width: 48px;
    height: 48px;

    &::before {
      width: 48px;
      height: 48px;
      transform: translateY(0);
    }

    @include small-tablet {
      width: 32px;
      height: 32px;

      &::before {
        width: 32px;
        height: 32px;
        transform: translateY(0);
      }
    }
  }
}
