.explore-job {
  @include flex-column;
  width: 100%;
  gap: var(--indent-xs);
  overflow: hidden;

  &._full {
    .explore-job__img {
      height: 208px;
    }

    > * {
      white-space: initial;
    }
  }

  &__img {
    width: 100%;
    height: 120px;
    border-radius: var(--indent-xs);
    overflow: hidden;

    &._big {
      height: 180px;
    }

    img {
      @extend %img-cover;
    }
  }

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
