.order-completion {
  @include flex-column;
  gap: var(--indent-xs);
  border-radius: var(--indent-m);
  padding: var(--indent-l);
  background-color: var(--color-grey);

  > * {
    color: #1b1b1b;
  }

  @include small-tablet {
    padding: var(--indent-s);
  }

  &__date {
    @include flex-center-start;
    gap: var(--indent-xs);

    @include before {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      transform: translateY(-2px);
      background-image: url("@img/icons/calendar-event.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}
