.order-contact {
  @include flex-center-start;
  @include flex-wrap;
  gap: var(--indent-s);

  &__photo {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;

    img {
      @extend %img-cover;
    }
  }

  &__info {
    @include flex-column;
  }
}
