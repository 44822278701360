.video {
  &._h400 {
    height: 400px;
  }
  &._w740 {
    max-width: 740px;
    width: 100%;
  }

  iframe {
    @extend %img-cover;
  }
}
