.task-info {
  @include flex-center-between;
  @include flex-wrap;
  max-width: 520px;
  gap: var(--indent-l);

  &__status {
    @extend .text-bold;
    color: var(--color-primary);
  }

  &__date {
    @extend .text-bold;

    @include tablet {
      order: 3;
    }
  }

  &__view {
    @include flex-all-center;
    @extend .text-bold;
    color: var(--color-primary);
    padding-left: 24px;
    position: relative;

    @include before {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      transform: translateY(-1px);
      background-color: currentColor;
      mask-image: url("@img/icons/eye-open.svg");
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;
    }
  }
}
