.custom-radio > .custom-radio__field {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio__content {
  display: inline-block;
  height: 100%;
  line-height: 28px;
  min-height: 28px;

  a {
    @extend .link-primary;
  }

  @include small-tablet {
    line-height: 20px;
  }
}

.custom-radio > .custom-radio__content {
  position: relative;
  padding-left: 38px;
  b {
    font-weight: 700;
  }
}

.custom-radio > .custom-radio__content::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid var(--color-primary);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
  position: absolute;
  left: 0;
  top: -2px;

  @include small-tablet {
    top: -4px;
  }
}

.custom-radio > .custom-radio__content::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0;

  @include small-tablet {
    top: 2px;
  }
}

.custom-radio > .custom-radio__field:checked + .custom-radio__content::after {
  opacity: 1;
}

.custom-radio > .custom-radio__field:disabled + .custom-radio__content::before {
  opacity: 0.5;
  pointer-events: none;
}
