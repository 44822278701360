.section-header {
  @include flex-start-between;
  @include flex-wrap;
  width: 100%;
  gap: var(--indent-s);

  &._start {
    gap: var(--indent-xl);
    align-items: center;
    justify-content: flex-start;
  }

  &._column {
    flex-direction: column;
    gap: var(--indent-xs);
  }
}
