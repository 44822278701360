.custom-grid {
  @include flex;
  gap: var(--indent-l);
  padding-bottom: var(--indent-xl);

  .list {
    li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }

  @include tablet {
    flex-direction: column;
  }

  &__coll1 {
    @include flex-column;
    gap: var(--indent-l);
    width: 100%;

    @include for-desktop {
      max-width: 500px;
      flex-shrink: 0;
    }
  }

  &__coll2 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--indent-l);

    @include small-tablet {
      grid-template-columns: 1fr;
    }

    .big {
      grid-column: 1 / 3;

      @include small-tablet {
        grid-column: 1;
      }
    }
  }
}
