.find-task {
  @include flex-column;
  gap: var(--indent-s);

  &__nav {
    @include flex-center-start;
    @include flex-wrap;
    gap: var(--indent-s);
  }

  &__link {
    @extend .third-title;
    padding: 0 var(--indent-s) 0px var(--indent-s);
    border-bottom: 4px solid var(--color-grey);
    transition:
      color 0.25s ease,
      border-color 0.25s ease;

    &:hover {
      border-color: var(--color-primary);
    }

    &._active {
      border-color: var(--color-primary);
    }
  }

  &__search {
    margin-bottom: 12px;
  }

  &__params {
    @include flex-start-between;
    @include flex-wrap;
    gap: var(--indent-l);
  }

  &__btns {
    @include flex-center-start;
    @include flex-wrap;
    gap: var(--indent-l);
  }

  &__btn {
    @include btn-reset;
    @include flex-all-center;
    @extend .text-bold;
    flex-shrink: 0;
    line-height: 1;
    gap: 8px;
    padding: 14px var(--indent-s) 10px;
    border-radius: var(--indent-s);
    border: 2px solid var(--color-grey);

    @include mobile {
      width: 100%;
    }

    svg {
      path {
        transition: stroke 0.25s ease;
        stroke: currentColor;
      }
    }
  }

  &__result {
    @extend .text;
    color: var(--color-grey-dark);
  }

  &__list {
    @include flex-column;
    gap: var(--indent-s);
  }

  &__map {
    border-radius: var(--indent-s);
    overflow: hidden;

    img {
      @extend %img-cover;
    }
  }

  &__recommended {
    padding: var(--indent-2xl) 0;
    width: 50%;

    @include tablet {
      padding-bottom: 400px;
    }
  }
}
