.review-small {
  @include flex-column;
  height: 100%;
  gap: var(--indent-m);
  padding: var(--indent-m) var(--indent-l);
  border-radius: var(--indent-s);
  background-color: var(--color-white);

  @include mobile {
    padding: var(--indent-m);
  }

  &__top {
    @include flex-center-between;
    gap: var(--indent-m);
  }

  &__header {
    @include flex-center-between;
    width: 100%;
    gap: var(--indent-m);

    @include mobile {
      align-items: start;
      flex-direction: column;
    }
  }

  &__bottom {
    @include flex-center-end;
    margin-top: auto;
    gap: var(--indent-m);
  }

  &__btns {
    @include flex;
    @include flex-wrap;
    margin-top: auto;
    gap: var(--indent-m);

    @include mobile {
      flex-direction: column;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn--primary;
    padding: 4px 6px !important;
  }

  &__author {
    @include flex-center-start;
    gap: var(--indent-s);
  }

  &__author-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;

    &._radius {
      border-radius: 10px;
    }

    img {
      @extend %img-cover;
    }
  }

  &__author-info {
    @include flex;
    @include flex-column;
    gap: 10px;
  }
}
