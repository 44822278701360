.custom-checkbox > .custom-checkbox__field {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox__content {
  a {
    @extend .link-primary;
  }

  @include small-tablet {
    line-height: 20px;
  }
}

.custom-checkbox > .custom-checkbox__content {
  position: relative;
  padding-left: 28px;

  b {
    font-weight: 700;
  }
}

.custom-checkbox > .custom-checkbox__content::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid var(--color-primary);
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
  position: absolute;
  left: 0;
  top: 2px;

  @include small-tablet {
    top: -2px;
  }
}

.custom-checkbox > .custom-checkbox__field:checked + .custom-checkbox__content::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2333cc00' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox > .custom-checkbox__field:disabled + .custom-checkbox__content::before {
  opacity: 0.5;
  pointer-events: none;
}

.custom-checkbox._flex .custom-checkbox__content {
  display: flex;
}
