.share {
  @include flex-center-start;
  gap: var(--indent-s);
  min-width: 260px;

  &__social {
    @include flex;
    gap: var(--indent-s);
  }
}
