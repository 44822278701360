.hero {
  width: 100%;
  height: 100%;
  min-height: 900px;
  background-color: var(--color-bg);
  position: relative;
  margin-top: -310px;
  padding-top: 330px;
  overflow: hidden;

  @include tablet {
    overflow: initial;
    min-height: auto;
  }

  &._no-content {
    @include tablet {
      display: none;
    }
  }

  &--auto {
    min-height: initial;
    height: auto;
    padding-bottom: var(--indent-2xl);
  }

  @include tablet {
    margin-top: 0px;
    padding-top: 0px;
    height: auto;
    padding-bottom: var(--indent-xl);
  }

  &__text {
    @include flex-column;
    @include flex-align-start;
    gap: var(--indent-s);
    max-width: 970px;

    &._bottom {
      max-width: 100%;
      width: 100%;
      margin-top: auto;
      margin-bottom: var(--indent-xl);

      @include tablet {
        margin-bottom: 0;
      }
    }
  }

  &__bg {
    opacity: 0.1;
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;

    &._opacity {
      opacity: 1;
    }

    @include tablet {
      display: none;
    }

    img {
      @extend %img-cover;
    }
  }

  &__gigo {
    position: absolute;
    z-index: 1;
    top: 100px;
    right: 0;
    animation: animate-gigo-hero 1s ease 2s both;

    @include big-desktop {
      width: 350px;
    }

    @include tablet {
      display: none;
    }

    > * {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__migo {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    right: -250px;

    @include tablet {
      display: none;
    }
  }

  &__container {
    @include flex-column;
    height: 100%;
    gap: var(--indent-2xl);
    position: relative;
    z-index: 2;

    @include tablet {
      gap: var(--indent-l);
    }
  }

  &__search-future {
    max-width: 970px;
    position: relative;

    @include tablet {
      max-width: 100%;
    }
  }
}

@keyframes animate-gigo-hero {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
