.dashboard-orders {
  @include flex-column;
  gap: var(--indent-s);
  padding: var(--indent-s);
  border-radius: var(--indent-s);
  border: 2px solid var(--color-grey);
  background-color: #ffffff;

  @include small-tablet {
    padding: 10px 12px;
  }

  &._services {
    background-color: var(--color-grey);
  }

  &__header {
    @include flex-center-between;
    border-radius: var(--indent-s);
    gap: var(--indent-s);
    padding: var(--indent-s) var(--indent-l);
    background-color: #ffffff;

    @include small-tablet {
      padding: 10px 12px;
      flex-wrap: wrap;
    }

    &._green {
      background-color: var(--color-light-green);
    }
  }
}
