.partners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--indent-l);

  &.grid5 {
    grid-template-columns: repeat(5, 1fr);
  }

  @include tablet {
    gap: var(--indent-s);
    grid-template-columns: repeat(2, 1fr);

    &.grid5 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include mobile {
    &.grid5 {
      grid-template-columns: 1fr;
    }

    grid-template-columns: 1fr;
  }

  &__item {
    @include flex-all-center;

    img {
      width: 100%;
    }
  }
}
