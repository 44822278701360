.gigos-story {
  @include flex;
  @include flex-align-stretch;
  @include flex-justify-start;
  gap: var(--indent-l);

  &__item {
    @include flex-column;
    gap: var(--indent-l);
    width: 200px;
    flex-shrink: 0;
    user-select: none;

    &:nth-of-type(odd) {
      .gigos-story__year {
        background-color: var(--color-red);
      }
    }
  }

  &__year {
    @include flex-all-center;
    padding: var(--indent-l);
    border-radius: var(--indent-l);
    color: #fff;
    background-color: var(--color-primary);
  }
}
