.notification-block {
  @include flex-all-center;
  @extend text;
  width: 100%;
  padding: var(--indent-l);
  gap: var(--indent-m);
  border-radius: var(--indent-s);
  background-color: var(--color-grey);
  color: #1b1b1b;
  text-align: center;
}
