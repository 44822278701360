.dashboard-contact {
  border-radius: var(--indent-s);
  padding: var(--indent-l);
  background-color: var(--color-grey);

  &__header {
    @include flex-center-between;
    gap: var(--indent-l);
    margin-bottom: var(--indent-l);
  }

  &__item {
    @include flex-column;
    gap: var(--indent-xs);

    &._hide {
      display: none;
    }

    @include not-last {
      margin-bottom: var(--indent-m);
    }
  }

  &__hours {
    @include flex-center-between;
    gap: var(--indent-xs);
    @include not-last {
      margin-bottom: var(--indent-s);
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn--primary;
    padding: 8px 18px !important;
  }

  &__btn-bottom {
    width: 100%;
    margin-top: auto;
  }
}
