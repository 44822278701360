.grid2-min {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--indent-l);

  &--auto {
    grid-template-columns: auto auto;
  }

  @include tablet {
    grid-template-columns: 1fr;
  }
}
