.featured-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--indent-l) var(--indent-xl);

  @include tablet {
    grid-template-columns: 1fr;
  }

  @include for-desktop {
    > * {
      grid-column: 2;

      &:nth-of-type(1) {
        grid-column: 1;
        grid-row: 1 / 5;
      }
    }
  }
}
