.select-filter {
  @include flex-all-center;

  &__select {
    @extend .select;
    @extend .text-bold;
    background-color: transparent;
    border: initial;
    color: var(--color-primary-dark);
    outline: none;
    box-shadow: initial;
    padding-top: 0;
    padding-bottom: 0;

    &:focus {
      border: initial;
      outline: none;
      box-shadow: initial;
    }
  }
}
