.author {
  @include flex-center-start;
  gap: var(--indent-s);

  &__ico {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: var(--indent-s);
  }

  &__text {
    @include flex-column;
    gap: var(--indent-xs);
  }
}
