.sense-of-belonging {
  @include flex-column;
  @include flex-justify-between;
  gap: var(--indent-l);
  padding: 100px var(--indent-l);
  border-radius: var(--indent-s);
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18) inset;

  &__text {
    @include flex-column;
    gap: var(--indent-s);
    margin-bottom: auto;
  }

  > * {
    color: #1b1b1b;
  }
}
