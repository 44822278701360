.grey-text {
  @include flex-column;
  gap: var(--indent-l);
  padding: var(--indent-xl);
  border-radius: var(--indent-s);
  background-color: var(--color-grey);

  @include small-tablet {
    padding: var(--indent-l);
  }

  > * {
    color: #1b1b1b;
  }

  &._green {
    > * {
      color: var(--color-primary);
    }
  }
}
