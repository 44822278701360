.header-account {
  position: relative;

  @include tablet {
    border-top: 3px solid var(--color-grey);
    border-bottom: 3px solid var(--color-grey);
    padding: var(--indent-l) 0;
  }

  /*@include before {
    position: absolute;
    z-index: 0;

    top: calc(-1 * var(--indent-s));
    left: calc(-1 * var(--indent-m));

    width: calc(100% + 2 * var(--indent-m));
    height: 130px;

    padding: var(--indent-s) var(--indent-m);

    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.25s ease;

    @include tablet {
      display: none;
    }

    @include small-tablet {
      height: 140px;
    }
  }*/

  ._active {
    position: relative;
    z-index: 3;

    @include tablet {
      justify-content: flex-start;
    }
  }

  @include hover {
    /*&::before {
      opacity: 1;
      pointer-events: auto;
    }*/

    .header-account__container {
      opacity: 1;
      pointer-events: auto;
    }

    > .header-btn {
      color: var(--color-primary-hover);
      white-space: nowrap;

      /*svg {
        path {
          stroke: var(--color-primary-hover);
        }
      }*/
    }
  }

  &__container {
    @include flex-column;
    gap: var(--indent-m);
    position: absolute;
    z-index: 2;
    top: -15px;
    left: calc(-1 * var(--indent-m));
    width: auto;

    padding: 55px var(--indent-m) var(--indent-s) var(--indent-m);

    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.25s ease;

    @include tablet {
      position: relative;
      opacity: 1;
      pointer-events: auto;
      top: initial;
      left: initial;
      width: 100%;
      background-color: transparent !important;
      box-shadow: initial;
      padding-top: 20px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &__link {
    justify-content: flex-start;
    color: #1b1b1b;

    svg {
      flex-shrink: 0;
    }
  }
}
