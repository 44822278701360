.dashboard-map {
  height: auto;
  border-radius: var(--indent-s);
  overflow: hidden;
  max-height: 290px;

  > * {
    @extend %img-cover;
  }
}
