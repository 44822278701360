.task-block {
  @include flex-column;
  width: 100%;
  gap: var(--indent-xl);
  padding: var(--indent-l);
  border-radius: var(--indent-s);
  background-color: var(--color-white);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);

  @include tablet {
    gap: var(--indent-l);
  }

  @include small-tablet {
    padding: var(--indent-l) var(--indent-s);
  }
}
