.check-list {
  @include flex-column;
  width: 100%;
  gap: var(--indent-l);

  li {
    position: relative;
    padding-left: 36px;

    @include before {
      width: 24px;
      height: 24px;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0px;
      background-color: currentColor;
      mask-image: url("@img/icons/check-circle-2.svg");
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;

      @include small-tablet {
        width: 16px;
        height: 16px;
      }
    }

    @include small-tablet {
      padding-left: 26px;
    }
  }
}
