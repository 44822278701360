.input-line {
  display: grid;
  gap: var(--indent-m);

  &--center {
    align-items: center;
  }

  &--1-2 {
    grid-template-columns: 1fr 2fr;
  }

  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    > .input {
      width: 100%;
    }

    > .input-line {
      width: 100%;
    }

    > .btn {
      flex-shrink: 0;
    }

    @include small-tablet {
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }

  &--flex-all {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    > .select {
      width: auto;
    }

    > .input {
      width: 100%;
    }
  }

  &--1-1 {
    grid-template-columns: 1fr 1fr;
  }

  &--1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include small-tablet {
    display: grid;
    grid-template-columns: 1fr;
  }
}
