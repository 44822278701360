.how-it-work {
  @include flex-stretch-between;
  @include flex-wrap;
  gap: var(--indent-l);

  > * {
    max-width: 318px;
  }

  &__item {
    @include flex-column;
    @include flex-align-start;
    gap: var(--indent-l);
  }
}
