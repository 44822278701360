.offer-author {
  @extend .review-small__author;
  width: 100%;
  padding: var(--indent-m);
  border-radius: var(--indent-s);
  background-color: var(--color-grey);

  &__img {
    @extend .review-small__author-avatar;
  }

  &__info {
    @include flex-column;
    gap: var(--indent-s);
  }

  &__rating {
    @include flex-center-start;
    gap: var(--indent-s);

    .rating {
      transform: translateY(-2px);
    }
  }
}
