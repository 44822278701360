.code {
  width: 100%;
  background-color: #1b1b1b;
  color: #fff;
  padding: var(--indent-l) 0;
  position: relative;

  &._grey {
    background-color: var(--color-grey);

    > * {
      color: #1b1b1b;
    }

    &::before,
    &::after {
      background-color: var(--color-grey);
    }
  }

  code {
    line-height: 1.5;
  }

  @include tablet {
    padding: var(--indent-l);
    overflow-y: auto;
  }

  @include before {
    width: calc((100vw - var(--container-width) + 50px) / 2);
    height: 100%;
    position: absolute;
    left: 2px;
    top: 0;
    transform: translateX(-100%);
    background-color: #1b1b1b;
  }

  @include after {
    width: calc((100vw - var(--container-width) + 50px) / 2);
    height: 100%;
    position: absolute;
    right: 2px;
    top: 0;
    transform: translateX(100%);
    background-color: #1b1b1b;
  }
}
